import React, { useEffect, useState } from "react";
import axios from "axios";
import * as z from "zod";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/@/components/ui/card";
import { Button } from "src/@/components/ui/button";
import { CHAT_MESSAGES, NUSA1, PRODUCTS } from "src/utils/constants";
import { Badge } from "src/@/components/ui/badge";
import { Input } from "src/@/components/ui/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/@/components/ui/tabs";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/@/components/ui/table";

import { cn } from "src/@/lib/utils";
import { LineChart } from "src/components/designSystem/organisms/LineChart";

const formSchema = z.object({
  to: z.string(),
  link: z.string(),
  product: z.string(),
  price: z.string(),
});

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const redirectUri = process.env.REACT_APP_REDIRECT_URI;

export const Dashboard: React.FC = () => {
  const [openChatText, setOpenChatText] = useState<number | undefined>(
    undefined
  );

  const [isLogin, setIsLogin] = useState(
    sessionStorage.getItem("instaLoginStatus")
  );

  const [SelectedChat, setSelectedChat] = useState(0);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      to: "",
      link: PRODUCTS[0].link,
      product: PRODUCTS[0].SKU,
      price: `${PRODUCTS[0].price}`,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    toast.success("Message sent!");
    setOpenChatText(undefined);
  }

  const setFormValues = (data: { to: string }) => {
    form.setValue("to", data.to);
  };

  const checkInstaLoginStatus = () => {
    return sessionStorage.getItem("instaLoginStatus");
  };

  const handleAuthorizationCode = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlSearchParams.get("code");
    if (authorizationCode) {
      try {
        const response = await axios.post(`${backendUrl}auth/facebook`, {
          code: authorizationCode,
        });
        axios
          .post(`${backendUrl}auth/facebook`, {
            code: authorizationCode,
          })
          .then((res) => {
            console.log("res", res);
          })
          .catch((err) => {
            console.log("====================================");
            console.log("err", err);
            console.log("====================================");
          });

        const data = response.data;
        console.log(data); // This will indicate success or any error messages from the backend
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };
  useEffect(() => {
    handleAuthorizationCode();
  }, []);

  const handleFacebookLogin = async () => {
    try {
      if (typeof FB !== "undefined" && typeof FB === "object") {
        console.log("Initiate the Facebook login process");
        FB.login(
          async (response) => {
            if (response.authResponse) {
              const accessToken = response.authResponse.accessToken;
              console.log("Facebook Access Token:", response, accessToken);
              // @ts-ignore
              localStorage.setItem("fbAccessToken", response);
              await getUserDetails({
                userId: response.authResponse.userID,
              });
              setIsLogin("true");
            } else {
              console.log("Facebook Login canceled or not authorized.");
            }
          },
          {
            scope:
              'instagram_basic,instagram_manage_messages,business_management,pages_show_list,instagram_manage_insights,instagram_manage_comments,pages_manage_metadata,pages_read_engagement,pages_messaging',
            // "instagram_basic,instagram_manage_insights,instagram_manage_messages,instagram_shopping_tag_products,pages_show_list,read_insights,email,pages_manage_metadata,pages_messaging,pages_read_engagement",
          }
          // {
          //   scope:"instagram_manage_comments,instagram_manage_insights,instagram_content_publish,instagram_manage_messages,pages_read_engagement,pages_read_user_content,public_profile"
          // }
        ); // Add additional permissions if needed
      } else {
        // 'FB' is not available, handle this case
        console.log("Facebook SDK not loaded.");
      }
    } catch (error) {
      console.log("fb login error", error);
    }
  };

  const getUserDetails = async ({ userId }: { userId: string }) => {
    if (typeof FB !== "undefined" && typeof FB === "object") {
      FB.api(`/${userId}/`, function (response) {
        // @ts-ignore
        if (response && !response.error) {
          console.log("response", response);
          // @ts-ignore
          localStorage.setItem("fbUserDetails", JSON.stringify(response));
        }
      });
      FB.api("/me/accounts", {}, function (response) {
        // @ts-ignore
        if (response && !response.error) {
          console.log("response", response);
          // @ts-ignore
          localStorage.setItem("fbUserPages", JSON.stringify(response));
          localStorage.setItem(
            "fbUserPageData",
            JSON.stringify({
              // @ts-ignore
              id: response.data[0].id,
              // @ts-ignore
              access_token: response.data[0].access_token,
            })
          );
          FB.api(
            // @ts-ignore
            `/${response.data[0].id}/${response.data[0].access_token}`,
            {},
            function (response) {
              // @ts-ignore
              if (response && !response.error) {
                console.log("response", response);
                // @ts-ignore
                localStorage.setItem("fbUserPages", JSON.stringify(response));
              }
            }
          );
        }
      });
    }
  };

  const getConersations = async () => {
    if (typeof FB !== "undefined" && typeof FB === "object") {
      const pageData = JSON.parse(
        // @ts-ignore
        localStorage.getItem("fbUserPageData")
      );
      FB.api(
        `/${pageData.id}/conversations`,
        {
          fields: `id,name,updated_time,messages{story,tags,message,created_time},participants&platform=instagram&access_token=${pageData.access_token}`,
        },
        function (response) {
          // @ts-ignore
          if (response && !response.error) {
            console.log("response", response);
            // @ts-ignore
            localStorage.setItem("fbConversations", JSON.stringify(response));
          }
        }
      );
    }
  };

  return (
    <>
      <div className="w-screen h-screen">
        <div className="flex items-baseline justify-center pt-20">
          <div className="flex flex-col items-center">
            <Card className="w-[500px]">
              <CardHeader>
                <CardTitle className="text-center">
                  Connect you facbook account
                </CardTitle>
              </CardHeader>
              <CardContent className="text-center">
                {isLogin !== "true" ? (
                  <Button
                    onClick={() => {
                      handleFacebookLogin();
                    }}
                  >
                    Login with Facebook
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      FB.logout();
                    }}
                  >
                    Logout
                  </Button>
                )}
              </CardContent>
              <CardFooter className="flex justify-between">
                {!checkInstaLoginStatus() && (
                  <p className="text-center">
                    Please connect your facebook account to get started with
                    Creator Gifting
                  </p>
                )}
              </CardFooter>
            </Card>

            {checkInstaLoginStatus() && (
              <Card className="w-[1000px] mt-20">
                <Button onClick={() => getConersations()}></Button>
                <Tabs defaultValue="account" className="w-[1000px]">
                  <TabsList>
                    <TabsTrigger value="account">Mentions</TabsTrigger>
                    <TabsTrigger value="password">Chat</TabsTrigger>
                    <TabsTrigger value="stats">Stats</TabsTrigger>
                  </TabsList>
                  <TabsContent value="account">
                    <Table>
                      <TableCaption>
                        A list of your Instagram posts that have mentioned you
                      </TableCaption>
                      <TableHeader>
                        <TableRow>
                          <TableHead className="w-[100px]">Name</TableHead>
                          <TableHead className="w-[100px]">Username</TableHead>
                          <TableHead>Followers</TableHead>
                          <TableHead>Following</TableHead>
                          <TableHead>Total posts</TableHead>
                          <TableHead>Post</TableHead>
                          <TableHead>Views</TableHead>
                          <TableHead>Comments</TableHead>
                          <TableHead>Likes</TableHead>
                          <TableHead>Time</TableHead>
                          <TableHead className="text-right">
                            Description
                          </TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        <TableRow>
                          <TableCell className="font-medium">
                            Hey Seva
                          </TableCell>
                          <TableCell className="font-medium">
                            <Badge>
                              <a href="https://www.instagram.com/heyseva_">
                                @heyseva_
                              </a>
                            </Badge>
                          </TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>7</TableCell>
                          <TableCell>2</TableCell>
                          <TableCell>
                            <img src={NUSA1} alt="Post" className="w-32" />
                          </TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>5</TableCell>
                          <TableCell>now</TableCell>
                          <TableCell className="text-right">
                            A day in bali #bali
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TabsContent>
                  <TabsContent value="password">
                    <div className="flex h-[300px] justify-between border-t">
                      <ul className="w-[300px] border-r">
                        {CHAT_MESSAGES.map((x, i) => (
                          <li
                            className={cn(
                              "flex justify-between px-6 py-4 border-b",
                              {
                                "bg-blue-500 text-white": SelectedChat === i,
                              }
                            )}
                            key={i}
                            onClick={() => {
                              setSelectedChat(i);
                            }}
                          >
                            <p>{x.name}</p>
                            {x.count !== 0 && <Badge>{x.count}</Badge>}
                          </li>
                        ))}
                      </ul>
                      <div className="w-full p-4">
                        <ul className="flex flex-col justify-end w-full h-full">
                          {CHAT_MESSAGES[SelectedChat].messages
                            .reverse()
                            .map((x, i) => (
                              <li
                                className={cn({
                                  "flex justify-start mb-2":
                                    x?.name !== "Other",
                                  "flex justify-end mb-2": x?.name === "Other",
                                })}
                                key={i}
                              >
                                <Badge
                                  className={
                                    x?.name === "Other"
                                      ? "bg-blue-500"
                                      : "bg-green-500"
                                  }
                                >
                                  {x?.message}
                                </Badge>
                              </li>
                            ))}
                          <li>
                            <div className="flex">
                              <Input placeholder="Type a message" />
                              <Button className="ml-2">Send</Button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </TabsContent>
                  <TabsContent value="stats">
                    <div className="p-10">
                      <LineChart width="744px" height="240px" />
                    </div>
                  </TabsContent>
                </Tabs>
              </Card>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
