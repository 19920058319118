import { getUserInfo, insertFBUser, updateUserInfo } from "src/api/login";
import styles from "./styles.module.css";
import { addPage } from "src/api/pages";
import { getFBConversations, getTaggedPosts } from "src/utils/FbApiHandlers";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { INSTA_PROFILES } from "src/utils/constants";
import { addCreator, getCreators } from "src/api/creators";

export const Connect = () => {
  const [isLogin, setisLogin] = useState(false);
  const [userDetail, setUserDetail] = useState<{
    _id: string;
    accessToken: string;
    name: string;
    instagramId: string;
    password: string;
    username: string;
    createdAt: string;
    updatedAt: string;
  }>({
    _id: "",
    accessToken: "",
    name: "",
    instagramId: "",
    password: "",
    username: "",
    createdAt: "",
    updatedAt: "",
  });

  const [loggedInUser, setloggedInUser] = useState("");

  let currentIndex = 0;

  const handleSequentialLoop = () => {
    if (currentIndex < INSTA_PROFILES.length) {
      if (typeof FB !== "undefined" && typeof FB === "object") {
        FB.api(
          `17841458242746072?fields=business_discovery.username(${INSTA_PROFILES[currentIndex].username}){followers_count,media_count,username,biography,name,website,profile_picture_url,media{caption,comments_count,id,like_count,media_type,permalink,thumbnail_url,timestamp,username}}`,
          async (res: any) => {
            // Do something with the current item, for example, log it
            if (res) {
              let payload = {};
              if (!res.error) {
                payload = {
                  ...res,
                  username: INSTA_PROFILES[currentIndex].username,
                  isActive: true,
                };
              } else {
                payload = {
                  reason: JSON.stringify(res.error, null, 2),
                  username: INSTA_PROFILES[currentIndex].username,
                  isActive: true,
                };
              }
              insertFBUser(payload).then((res) => {
                // Update the index for the next iteration
                currentIndex = currentIndex + 1;

                // Set a delay before the next iteration
                // setTimeout(handleSequentialLoop, 20000); // Adjust the delay time as needed
              });
            }
          }
        );
      }
    }
  };

  const getUserPageInfo = () =>
    getUserInfo().then((res: any) => {
      if (res) {
        setUserDetail(res);
        if (res?.accessToken?.length) {
          if (typeof FB !== "undefined" && typeof FB === "object") {
            FB.getLoginStatus((response) => {
              if (response.status === "connected") {
                setisLogin(true);
                FB.api(`/${res.instagramId}/`, async (userResponse: any) => {
                  // @ts-ignore
                  if (userResponse && !userResponse.error) {
                    setloggedInUser(userResponse?.name);
                  }
                });
              }
            });
          }
        }
      }
    });

  useEffect(() => {
    getUserPageInfo();
  }, []);

  const handleFacebookLogout = async () => {
    if (typeof FB !== "undefined" && typeof FB === "object") {
      FB.logout(async (response) => {
        setisLogin(false);
        await updateUserInfo({
          accessToken: "",
          name: userDetail?.name,
          instagramId: userDetail?.instagramId,
        });
        setUserDetail({
          ...userDetail,
          accessToken: "",
        });
        toast.success("Logged out successfully");
      });
    }
  };

  const handleFacebookLogin = async () => {
    try {
      if (typeof FB !== "undefined" && typeof FB === "object") {
        FB.login(
          async (response) => {
            if (response.authResponse) {
              FB.api(
                `/${response.authResponse.userID}/`,
                async (userResponse: any) => {
                  // @ts-ignore
                  if (userResponse && !userResponse.error) {
                    await updateUserInfo({
                      accessToken: response.authResponse.accessToken,
                      name: userResponse?.name,
                      instagramId: userResponse?.id,
                    });
                    setisLogin(true);
                    setloggedInUser(userResponse?.name);
                    toast.success("Logged in successfully");
                  }
                }
              );

              FB.api("me/accounts?platform=instagram", (pages: any) => {
                const page = pages.data[0];
                FB.api(
                  `${page.id}?fields=connected_instagram_account,name,category,access_token,picture{url},followers_count,fan_count,about`,
                  "get",
                  {
                    access_token: page.access_token,
                  },
                  // "me/accounts?fields=connected_instagram_account,name,category,access_token",
                  (res: any) => {
                    console.log("pages---", res);
                    [res].forEach(async (page: any) => {
                      FB.api(
                        `${page.connected_instagram_account.id}?fields=follows_count,username,followers_count,biography,profile_picture_url`,
                        "get",
                        {
                          access_token: page.access_token,
                        },
                        async (profile: any) => {
                          const pageRes = await addPage({
                            accessToken: page.access_token,
                            category: page.category,
                            name: page.name,
                            pageId: page.id,
                            connectedId: page.connected_instagram_account.id,
                            followers: `${profile.followers_count}`,
                            following: `${profile.follows_count}`,
                            about: profile.biography,
                            picture: profile.profile_picture_url,
                            username: profile.username,
                          });
                          await getFBConversations(
                            page.id,
                            page.access_token,
                            pageRes?.data?._id
                          );
                        }
                      );

                      getUserPageInfo();
                    });
                  }
                );
              });
            } else {
              console.log("Facebook Login canceled or not authorized.");
            }
          },
          {
            scope:
              // "instagram_basic,instagram_manage_insights,instagram_manage_messages,pages_show_list,read_insights,email,pages_manage_metadata,pages_messaging,pages_read_engagement",
              // "instagram_basic,instagram_manage_messages,pages_show_list,instagram_manage_comments,pages_read_engagement",
              // "instagram_basic,instagram_manage_messages,pages_show_list",
              'instagram_basic,instagram_manage_messages,business_management,pages_show_list,instagram_manage_insights,instagram_manage_comments,pages_manage_metadata,pages_read_engagement,pages_messaging',

            // "pages_messaging,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_manage_messages,pages_read_engagement,pages_read_user_content",
          }
        );
      } else {
        console.log("Facebook SDK not loaded.");
      }
    } catch (error) {
      console.log("fb login error", error);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.cardBlock}>
          <div className={styles.cardHeaderBlock}>
            {userDetail.accessToken.length && isLogin ? (
              <p className={styles.cardHeaderBlockTitle}>
                Connected with Instagram @{loggedInUser}
              </p>
            ) : (
              <p className={styles.cardHeaderBlockTitle}>
                Connect with Instagram
              </p>
            )}
          </div>
          <div className={styles.cardBodyBlock}>
            {isLogin ? (
              <button
                className={styles.primaryButton}
                onClick={handleFacebookLogout}
              >
                Disconnect
              </button>
            ) : (
              <button
                className={styles.primaryButton}
                onClick={handleFacebookLogin}
              >
                Continue with Facebook
              </button>
            )}
          </div>
          {!isLogin && (
            <div className={styles.cardFooterBlock}>
              <h4 className={styles.cardFooterTitleStyle}>
                SEVA needs permission from Instagram to:
              </h4>
              <ul className={styles.cardFooterListStyle}>
                <li>View basic information about your business account</li>
                <li>View your mentions in stories via messages </li>
              </ul>
              <h4 className={styles.cardFooterTitleStyle}>
                Make sure you have completed these steps:
              </h4>
              <ul className={styles.cardFooterListStyle}>
                <li>
                  Converted your account to a Business Account in the instagram
                  app.
                  <Link
                    to="https://help.instagram.com/502981923235522"
                    className={styles.linkStyle}
                  >
                    Learn more
                  </Link>
                </li>
                <li>
                  Attached your Facebook Page to your Instagram Business
                  Account.
                  <Link
                    to="https://help.instagram.com/570895513091465"
                    className={styles.linkStyle}
                  >
                    Learn more
                  </Link>
                </li>
                <li>
                  Either own the Facebook Page, or have admin access granted to
                  you through Facebook Page or Meta Business Manager. For help
                  contact us via <span>support@heyseva.com</span> for detailed
                  guidance.
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
